// import Frame from "./components/Frame";
import React, { useState } from "react";

import "./App.css";

import SortIcon from "@mui/icons-material/Sort";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import SearchIcon from "@mui/icons-material/Search";

const Frame = React.lazy(() => import("./components/Frame"));

function App() {
  const [search, setSearch] = useState("");
  const [ascendente, setAscendente] = useState("");
  const [depart, setDepart] = useState("");

  const Orden_card = (e) => {
    setAscendente(e.target.value);
  };
  const Search_depart = (e) => {
    setDepart(e.target.value);
  };

  let buscar = (e) => {
    let busqueda = e.target.value.toLowerCase();
    setSearch(busqueda);
  };

  return (
    <React.Fragment>
      <header className="header">
        <SearchIcon className="Icon-search" sx={{ fontSize: 20 }} />
        <input
          onChange={buscar}
          className="search"
          placeholder="Buscar..."
          title
        />

        <div className="dropbox-container">
          <LibraryBooksIcon className="Icon-depart" sx={{ fontSize: 20 }} />
          <select className="select-section" onChange={Search_depart}>
            <option>Departamentos</option>
            <option value="dirección ejecutiva">Dirección Ejecutiva</option>
            <option value="administración y finanzas">
              Administración y finanzas
            </option>
            <option value="comunicaciones">Comunicaciones</option>
            <option value="planificación y desarrollo">
              Planificación y Desarrollo
            </option>
            <option value="relaciones internacionales">
              Relaciones Internacionales
            </option>
            <option
              value="investigación y e.m"
              title=" Investigación y estudios migratorios"
            >
              Investigación y estudios M...
            </option>
            <option
              value="escuela nacional de migración"
              title="Escuela Nacional de Migración"
            >
              Escuela Nacional de M...
            </option>
            <option value="transparencia">Transparencia</option>
            <option value="recursos humanos">Recursos humanos</option>
            <option value="tecnología">Tecnología</option>
            <option value="cocina">Cocina</option>
          </select>
          <SortIcon className="Icon-orden" sx={{ fontSize: 18 }} />
          <select className="select-orden" onChange={Orden_card}>
            <option>Orden</option>
            <option value="A">Ascendente</option>
            <option value="B">Descendente</option>
          </select>
        </div>
      </header>

      <Frame input={search} orden={ascendente} occupation={depart} />
    </React.Fragment>
  );
}

export default App;
